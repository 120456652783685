<template>
  <div>
    <div class="income-detail">
      <topbar title="佣金明细" />
      <div class="container">
        <div class="tab">
          <div
            class="item"
            :class="{ active: tab.active === index }"
            v-for="(item, index) in money"
            :title="item.name"
            :key="index"
            @click="tab.active = index"
          >
            {{ item.name }}
          </div>
        </div>

        <div class="summary">
          <div class="time">
            <div
              style="
                padding: 5px 10px;
                background: #fff;
                border-radius: 5px;
                font-size: 14px;
                color: rgb(170, 170, 170);
                display: flex;
                align-items: center;
              "
            >
              <van-icon @click="changeTime(-1)" name="arrow-left" color="#333" /><span
                style="font-size: 11px; margin: 0 10px; color: #333"
                @click="changeTime(-1)"
              >
                前一天</span
              ><span @click="show = true" style="font-size: 11px">{{ date }}</span>
              <span style="font-size: 11px; margin: 0 10px; color: #333" @click="changeTime(1)"
                >后一天
              </span>
              <van-icon name="arrow" color="#333" @click="changeTime(1)" />
            </div>
            <van-button @click="resetTime" style="margin-left: 10px" size="mini">清空</van-button>
          </div>

          <van-calendar
            v-model="show"
            :min-date="new Date(2000, 0, 1)"
            type="range"
            @confirm="onConfirm"
          />

          <div class="income">
            <div class="item">
              <div class="text">{{ current.amountText }}（元）</div>
              <div class="number">{{ current.amount }}</div>
            </div>

            <div class="item">
              <div class="text">{{ current.countText }}（笔）</div>
              <div class="number">{{ current.count }}</div>
            </div>
          </div>
        </div>
        <div style="height: 10px; background: #f5f5f5"></div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
          "
        >
          <van-search
            v-model="searchKey"
            placeholder="请输入商品名/订单号"
            shape="round"
            style="margin-top: 10px; width: 100%"
            :clearable="false"
            @search="getData(1)"
            @blur="getData()"
          ></van-search>
          <!-- <select v-model="distributionSource" class="select">
            <option value="">全部</option>
            <option value="0">直售佣金</option>
            <option value="1">团队佣金</option>
          </select> -->

          <!-- <van-icon name="arrow-up" class="selectIcon" /> -->
        </div>

        <van-list
          class="list"
          v-model="loading"
          :finished="!isMore"
          finished-text="没有更多了"
          @load="loadMore"
        >
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="user">
              <div class="info">
                <span class="name">订单号：{{ item.orderId }}</span>
              </div>
              <div class="status">{{ item.distributionStatusTitle }}</div>
            </div>
            <div class="product">
              <img class="img" :src="item.skuPic + '?x-oss-process=style/thumb_600'" />
              <div class="detail">
                <div class="title">{{ item.productName }}</div>
                <div class="subtitle">{{ item.skuName }}</div>
                <div class="amount">
                  核销金额: <span class="money">￥{{ item.actualPayMoney }}</span>
                </div>
              </div>
            </div>

            <div class="bottom">
              <div class="date">下单时间：{{ item.orderCreateTime | formatFullDate }}</div>

              <div
                v-if="
                  item.distributionStatusTitle == '待入账' ||
                    item.distributionStatusTitle == '已退款'
                "
              >
                <template
                  v-if="item.incomeAmountMin && item.incomeAmountMin != item.incomeAmountMax"
                >
                  {{ item.distributionSourceTitle }}：<span class="money"
                    >{{ item.incomeAmountMin }}~{{ item.incomeAmountMax }}</span
                  >
                  元
                </template>
                <template v-else>
                  {{ item.distributionSourceTitle }}：<span class="money">{{
                    item.incomeAmount || item.incomeAmountMin
                  }}</span>
                  元
                </template>
              </div>
              <div v-else>
                {{ item.distributionSourceTitle }}：<span class="money">{{
                  item.incomeAmount
                }}</span>
                元
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: '开始时间-结束时间',
      show: false,
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      distributionSource: '',
      seedDate: [],
      tab: {
        list: [],
        active: 0
      },
      summary: {
        total: {
          amount: 80,
          count: 30,
          amountText: '预估CPS佣金收入',
          countText: '预估CPS佣金收入笔数'
        },
        pending: {
          amount: 30,
          count: 230,
          amountText: '待入账佣金',
          countText: '待入账佣金笔数'
        },
        credited: {
          amount: 90,
          count: 20,
          amountText: '已入账佣金',
          countText: '已入账佣金笔数'
        },
        invalid: {
          amount: 20,
          count: 40,
          amountText: '已退款佣金',
          countText: '已退款佣金笔数'
        }
      },
      current: { amount: 0, count: 0, amountText: '待入账佣金', countText: '待入账佣金笔数' },
      money: [
        { name: '全部', value: 0, prop: 'totalAmount' },
        { name: '待入账', value: 0, prop: 'pendingTotalAmount' },
        { name: '已入账', value: 0, prop: 'creditedTotalAmount' },
        { name: '退款', value: 0, prop: 'invalidTotalAmount' }
      ],
      searchKey: '',
      list: [],
      loading: false,
      refreshing: false,
      page: 1,
      isMore: true
    }
  },
  created() {
    this.getData()
    this.getSummary()
  },
  watch: {
    date() {
      this.getSummary()
      this.getData()
    },
    distributionSource(v) {
      this.getData()
    },
    'tab.active': {
      handler(v) {
        this.isMore = true
        this.page = 1
        this.getData()
        this.current = this.summary[Reflect.ownKeys(this.summary)[v]]
      }
    }
  },

  methods: {
    resetTime() {
      this.date = `开始时间-结束时间`
      this.seedDate = []
    },
    changeTime(value) {
      let date = []
      if (value == 1) {
        date = [
          new Date(this.seedDate[0].getTime() + 3600 * 1000 * 24),
          new Date(this.seedDate[1].getTime() + 3600 * 1000 * 24)
        ]
      } else {
        date = [
          new Date(this.seedDate[0].getTime() - 3600 * 1000 * 24),
          new Date(this.seedDate[1].getTime() - 3600 * 1000 * 24)
        ]
      }
      this.onConfirm(date)
    },
    getSummary() {
      this.$http
        .get('/boom-center-user-service/app/distributionBill/summary', {
          params: {
            endOrderTime:
              this.seedDate.length > 0 ? new Date(this.seedDate[1]).getTime() : undefined,
            startOrderTime:
              this.seedDate.length > 0 ? new Date(this.seedDate[0]).getTime() : undefined
          }
        })
        .then(res => {
          Reflect.ownKeys(this.summary).forEach(item => {
            this.summary[item] = { ...this.summary[item], ...res[item] }
          })
          this.current = this.summary['total']
        })
    },

    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm(date) {
      const [start, end] = date
      console.log(date)
      this.seedDate = date
      this.show = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    // onRefresh() {
    //   this.refreshing = true
    //   this.getData()
    // },

    loadMore() {
      if (this.isMore) {
        this.getData(this.page + 1)
      }
    },
    handleClear() {
      this.searchKey = ''
      console.log(this.searchKey)
      this.getData()
    },
    getData(page = 1) {
      if (page <= 0) return
      this.loading = true
      this.$http
        .get('boom-center-user-service/app/distributionBill', {
          params: {
            endOrderTime:
              this.seedDate.length > 0 ? new Date(this.seedDate[1]).getTime() : undefined,
            startOrderTime:
              this.seedDate.length > 0 ? new Date(this.seedDate[0]).getTime() : undefined,
            distributionSource: this.distributionSource,
            distributionStatus: this.tab.active - 1,
            queryStr: this.searchKey,
            page
          }
        })
        .then(res => {
          let list = this.list
          if (page === 1) list = []
          this.list = [...list, ...res.list]
          this.page = page
          this.isMore = page * res.size < res.total
        })
        .finally(() => {
          this.loading = false
          this.refreshing = false
        })
    }
  }
}
</script>

<style lang="less" scope>
.income-detail {
  min-height: 100vh;
  .container {
    .record {
      background: #fde7e8;
      border-radius: 5px;
      padding: 15px 0;
      margin: 15px;
      .item {
        text-align: center;
        padding: 5px;
        .title {
          font-size: 10px;
          color: #666;
        }
        .money {
          color: #e7141a;
          margin-top: 8px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .van-tabs__wrap {
      position: sticky;
      top: 59px;
    }

    .list {
      .item {
        border-bottom: 1px solid #f8f8f8;
        padding: 15px;
        .user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .info {
            display: flex;
            align-items: center;
            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
            }
            .name {
              font-size: 12px;
              // font-weight: bold;
              color: #999;
            }
          }
          .status {
            font-size: 13px;
            color: #e7141a;
          }
        }
        .product {
          display: flex;
          overflow: hidden;
          .img {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            object-fit: cover;
          }
          .detail {
            flex: 1;
            overflow: hidden;
            font-size: 12px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 16px;
              font-weight: bold;
              color: #333;
            }
            .subtitle {
              font-size: 12px;
              color: #999;
            }
            .amount {
              font-size: 12px;
              color: #333;
              .money {
                font-size: 18px;
                color: #e7141a;
              }
              .price {
                color: #e7141a;
              }
              .symbol {
                color: #333;
              }
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #999;
          margin-top: 15px;
          .money {
            color: #e7141a;
          }
        }
      }
    }
  }
}
.summary {
  margin: 20px;
  border-radius: 8px;

  background: #f5f5f5;
  .time {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
  }
  .income {
    display: flex;
    justify-content: space-around;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      .text {
        font-size: 12px;
        margin: 6px 0;
        font-weight: 600;
      }
      .number {
        font-weight: 600;
        color: #e7141a;
      }
    }
  }
}
.tab {
  display: flex;
  justify-content: space-around;
  margin: 12px 0;
  padding-top: 12px;
  .item {
    border-radius: 5px;
    background: #f5f5f5;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 600;
  }
  .active {
    background: #e7141a;
    color: #fff;
  }
}

.select {
  margin-top: 10px;
  -webkit-appearance: none;
  background-color: #fff;
  width: 25%;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 29px;
  margin-right: 8px;
  line-height: 29px;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.select:focus + .selectIcon {
  transform: rotateX(180deg);
}
.select:hover .selectIcon {
  transform: rotateX(180deg);
}
.selectIcon {
  position: absolute;
  color: #c0c4cc;
  right: 19px;
  top: 31px;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
  font-size: 12px;
}
</style>
